import React from "react"
import Link from "../link"

import { testIds } from "../../common/constants"
import * as styles from "./office.module.css"

const { officeList } = testIds.global.footer

const Office = ({
  businessId,
  city,
  country,
  streetAddress,
  zipCode,
  link = "/",
  contactDetailsText,
}) => (
  <div
    id="office"
    data-cy={officeList.office}
    className="column is-flex is-flex-direction-column"
  >
    <h2 className="is-size-6 pb-2">{city}</h2>
    <div className="is-flex is-flex-direction-column content">
      <span>{streetAddress}</span>
      <span>
        {zipCode} {city}, {country}
      </span>
      <span>Business ID {businessId}</span>
      <Link to={link} data-test-id={officeList.link} className={styles.link}>
        {city} {contactDetailsText}
      </Link>
    </div>
  </div>
)

export default Office
