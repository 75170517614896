import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlockList from "../components/block-list"
import RichText from "../components/rich-text"
import Article from "../components/article"
import PageFooter from "../components/page-footer"
import { getDescription, getMetaImageSrc, getTitle } from "../common/utils"

export const Head = ({ data: { page }, location }) => (
  <SEO
    title={getTitle(page)}
    description={getDescription(page)}
    metaImage={getMetaImageSrc(page)}
    metaTwitterCardType={page.twitterSharePreviewType}
    searchIndexing={page.searchIndexing}
    pathname={location?.pathname}
  />
)

const Page = ({ data: { page }, pageContext }) => (
  <Layout pageContext={pageContext} footer={<PageFooter />}>
    {page.before && <BlockList data={page.before} />}
    {page.content && (
      <Article>
        <RichText content={page.content} />
      </Article>
    )}
    {page.after && <BlockList data={page.after} />}
  </Layout>
)

export const query = graphql`
  query ($contentfulId: String!) {
    page: contentfulPage(contentful_id: { eq: $contentfulId }) {
      searchIndexing
      metaTitle
      metaDescription {
        metaDescription
      }
      metaImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      twitterSharePreviewType
      content {
        raw
        references {
          ... on ContentfulTableBlock {
            ...TableBlock
          }
          ... on ContentfulVideoEmbedBlock {
            ...VideoEmbedBlock
          }
        }
      }
      before {
        __typename
        ... on Node {
          ... on ContentfulHeaderBlock {
            ...HeaderBlock
          }
          ... on ContentfulSectionBlock {
            ...SectionBlock
          }
          ... on ContentfulQuoteBlock {
            ...QuoteBlock
          }
          ... on ContentfulImageBlock {
            ...ImageBlock
          }
          ... on ContentfulOfficeBlock {
            ...OfficeBlock
          }
          ... on ContentfulStatisticsBlock {
            ...StatisticsBlock
          }
          ... on ContentfulLogosBlock {
            ...LogosBlock
          }
          ... on ContentfulContactBlock {
            ...ContactBlock
          }
          ... on ContentfulHubSpotFormBlock {
            ...HubSpotFormBlock
          }
          ... on ContentfulComponentBlock {
            ...ComponentBlock
          }
          ... on ContentfulHyperlinkButtonBlock {
            ...HyperlinkButtonBlock
          }
          ... on ContentfulTableBlock {
            ...TableBlock
          }
          ... on ContentfulHubSpotMeetingBlock {
            ...HubSpotMeetingBlock
          }
          ... on ContentfulVideoEmbedBlock {
            ...VideoEmbedBlock
          }
          ... on ContentfulImageLinkListBlock {
            ...ImageLinkListBlock
          }
          ... on ContentfulOpenPositionsListBlock {
            ...OpenPositionsListBlock
          }
        }
      }
      after {
        __typename
        ... on Node {
          ... on ContentfulHeaderBlock {
            ...HeaderBlock
          }
          ... on ContentfulSectionBlock {
            ...SectionBlock
          }
          ... on ContentfulQuoteBlock {
            ...QuoteBlock
          }
          ... on ContentfulImageBlock {
            ...ImageBlock
          }
          ... on ContentfulOfficeBlock {
            ...OfficeBlock
          }
          ... on ContentfulStatisticsBlock {
            ...StatisticsBlock
          }
          ... on ContentfulLogosBlock {
            ...LogosBlock
          }
          ... on ContentfulContactBlock {
            ...ContactBlock
          }
          ... on ContentfulHubSpotFormBlock {
            ...HubSpotFormBlock
          }
          ... on ContentfulComponentBlock {
            ...ComponentBlock
          }
          ... on ContentfulHyperlinkButtonBlock {
            ...HyperlinkButtonBlock
          }
          ... on ContentfulTableBlock {
            ...TableBlock
          }
          ... on ContentfulHubSpotMeetingBlock {
            ...HubSpotMeetingBlock
          }
          ... on ContentfulVideoEmbedBlock {
            ...VideoEmbedBlock
          }
          ... on ContentfulImageLinkListBlock {
            ...ImageLinkListBlock
          }
          ... on ContentfulOpenPositionsListBlock {
            ...OpenPositionsListBlock
          }
        }
      }
    }
  }
`

export default Page
