import React from "react"

import Link from "../link"
import * as styles from "./social-media.module.css"
import { testIds } from "../../common/constants"

const SocialMedia = ({ channels, title }) => {
  const { socialMedia } = testIds.global.footer

  return (
    <div className="block">
      <span data-cy={socialMedia.title} className={styles.title}>
        {title}
      </span>
      <div className="mt-3">
        {channels.map(({ id, altText, icon, url }) => (
          <Link key={id} to={url} className="mr-5">
            <img data-cy={socialMedia.icon} src={icon.url} alt={altText} />
          </Link>
        ))}
      </div>
    </div>
  )
}

export default SocialMedia
