import React from "react"

import Office from "./office"
import * as styles from "./office-list.module.css"
import { testIds } from "../../common/constants"

const OfficeList = ({ offices, title, contactDetailsText }) => (
  <div className="block">
    <span
      data-cy={testIds.global.footer.officeList.title}
      className={styles.title}
    >
      {title}
    </span>
    <div className="columns mt-1">
      {offices.map(office => (
        <Office
          key={office.id}
          businessId={office.businessId}
          city={office.city}
          country={office.country}
          streetAddress={office.streetAddress}
          zipCode={office.zipCode}
          link={`/contact#${office.city}`}
          contactDetailsText={contactDetailsText}
        />
      ))}
    </div>
  </div>
)

export default OfficeList
