import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as styles from "./page-footer.module.css"
import Legal from "../legal"
import Logo from "../logo"
import OfficeList from "./office-list"
import SocialMedia from "./social-media"

const PageFooter = () => {
  const { contentfulPageFooter } = useStaticQuery(graphql`
    query {
      contentfulPageFooter {
        socialChannelsListTitle
        officesListTitle
        contactDetailsText
        offices {
          city
          country
          id
          name
          zipCode
          streetAddress
          businessId
        }
        socialChannels {
          id
          altText
          icon {
            url
          }
          url
        }
      }
    }
  `)
  const {
    contactDetailsText,
    offices,
    officesListTitle,
    socialChannelsListTitle,
    socialChannels,
  } = contentfulPageFooter

  return (
    <footer className={styles.wrapper}>
      <div className="container">
        <div className="columns is-desktop mt-2">
          <div className="column is-2">
            <Logo inverse />
          </div>
          <div className="column">
            <OfficeList
              offices={offices}
              title={officesListTitle}
              contactDetailsText={contactDetailsText}
            />
            <SocialMedia
              channels={socialChannels}
              title={socialChannelsListTitle}
            />
            <Legal />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default PageFooter
